import React from "react"

import Container from "../../components/UI/Container"
import TitleSection from "../TitleSection"

import SectionWrapper, { Section, ColumnB, ColumnA } from "./whoWeAre.style"

const Customer = ({ content }) => {
  return (
    <SectionWrapper>
      <Container width="1220px">
        <TitleSection
          titleA={content.title.html}
          titleB={content.title_colored.html}
          subtitle={content.subtitle.html}
        />
        <Section>
          <ColumnA>
            <div dangerouslySetInnerHTML={{ __html: content.column_a.html }} />
          </ColumnA>
          <ColumnB>
            <div dangerouslySetInnerHTML={{ __html: content.column_b.html }} />
          </ColumnB>
        </Section>
      </Container>
    </SectionWrapper>
  )
}

export default Customer
