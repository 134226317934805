import SectionWrapper, { ImageWrapper, TextWrapper } from "./mission.style"

import Container from "../../components/UI/Container"
import Img from "gatsby-image"
import React from "react"
import Text from "../../components/Text"
import TitleSection from "../TitleSection"
import striptags from "striptags"

const Mission = ({ content }) => {
  const { items, primary } = content

  return (
    <SectionWrapper>
      <Container width="1220px">
        <TitleSection
          titleA={primary.title.html}
          titleB={primary.title_colored.html}
          subtitle={primary.subtitle.html}
          subTitleMaxWidth="520px"
        />
      </Container>
      <Container width="1220px" className="content">
        <TextWrapper>
          {items.map((item, index) => {
            return (
              <div key={index} className="details">
                <Text content={striptags(item.title.html)} className="title" />
                <hr className="seperator" />
                <Text content={item.content} className="content" />
              </div>
            )
          })}
        </TextWrapper>
        <ImageWrapper>
          <Img
            fadeIn={false}
            loading="lazy"
            className="image"
            fluid={primary.image.localFile?.childImageSharp.fluid}
            alt="mission-img"
          />
        </ImageWrapper>
      </Container>
    </SectionWrapper>
  )
}

export default Mission
