import React from "react"
import PropTypes from "prop-types"
import Box from "../../components/Box"
import Text from "../../components/Text"
import Heading from "../../components/Heading"
import FeatureBlock from "../../components/FeatureBlock"
import Container from "../../components/UI/Container"
import TitleSection from "../TitleSection"
import WhyUsSectionWrapper from "./whyUs.style"

const WhyUsSection = ({
  row,
  col,
  whyUsTitle,
  number,
  whyUsDescription,
  contentStyle,
  blockWrapperStyle,
  content,
}) => {
  const { WhyUs, items } = content

  return (
    <WhyUsSectionWrapper>
      <Container width="1220px">
        <TitleSection
          titleA={WhyUs.title.html}
          titleB={WhyUs.title_colored.html}
          subtitle={WhyUs.subtitle.html}
          subTitleMaxWidth="544px"
        />
        <Box className="row" {...row}>
          {items.map((feature, index) => (
            <Box className="col" {...col} key={index}>
              <FeatureBlock
                number={
                  <Heading
                    content={("0" + (index + 1)).toString()}
                    {...number}
                  />
                }
                wrapperStyle={blockWrapperStyle}
                contentStyle={contentStyle}
                title={<Heading content={feature.title.text} {...whyUsTitle} />}
                description={
                  <Text content={feature.content} {...whyUsDescription} />
                }
                className="whyUs"
              />
            </Box>
          ))}
        </Box>
      </Container>
    </WhyUsSectionWrapper>
  )
}

// WhyUsSection style props
WhyUsSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  whyUsTitle: PropTypes.object,
  number: PropTypes.object,
  whyUsDescription: PropTypes.object,
  blockWrapperStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  content: PropTypes.object,
}

// WhyUsSection default style
WhyUsSection.defaultProps = {
  // WhyUsSection row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  // WhyUsSection col default style
  col: {
    width: ["100%", "100%", "47%", "29%"],
  },
  // WhyUsSection block wrapper default style
  blockWrapperStyle: {
    pb: ["220px", "20px", "20px", "30px"],
    pt: ["20px", "20px", "20px", "30px"],
  },

  // WhyUsSection content default style
  contentStyle: {
    textAlign: "left",
  },
  // WhyUsSection title default style
  whyUsTitle: {
    fontSize: ["18px", "18px", "20px", "22px"],
    fontWeight: "700",
    color: "#000",
    mb: ["24px", "16px", "16px", "16px"],
    minHeight: ["30px", "50px"],
  },
  // WhyUsSection number default style
  number: {
    textAlign: "center",
    lineHeight: "1.8",
    display: "inline-block",
    fontSize: ["18px", "18px"],
    fontWeight: "700",
    color: "#000",
    mb: ["20px", "20px", "32px", "32px"],
    border: "1px solid #000",
    width: "65px",
    height: "65px",
    p: "15px",
  },
  // WhyUsSection description default style
  whyUsDescription: {
    fontWeight: "300",
    fontSize: ["16px", "18px"],
    lineHeight: "1.5",
    color: "#000",
  },
}

export default WhyUsSection
