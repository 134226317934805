import styled from "styled-components"

const SectionWrapper = styled.section`
  padding: 100px 0 100px;
  @media only screen and (max-width: 990px) {
    padding: 80px 0 80px;
  }
  @media only screen and (max-width: 480px) {
    padding: 50px 0 50px;
  }
`

export const Section = styled.div`
  display: flex;
  @media only screen and (max-width: 990px) {
    flex-direction: column;
  }
  p {
    margin-top: 0px;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    @media only screen and (max-width: 990px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 16px;
      line-height: 28px;
    }
  }
`

export const ColumnA = styled.figure`
  width: 36%;
  margin: 0 10% 0 0;
  @media only screen and (max-width: 990px) {
    width: 100%;
    margin: 0 0 0 0;
  }
`
export const ColumnB = styled.div`
  width: 54%;
  @media only screen and (max-width: 990px) {
    width: 100%;
  }
`

export default SectionWrapper
