import styled from "styled-components"

const WhyUsSectionWrapper = styled.section`
  padding: 100px 0 100px 0;

  @media (max-width: 990px) {
    padding: 80px 0 80px 0;
  }
  @media (max-width: 480px) {
    padding: 50px 0 50px 0;
  }

  .whyUs {
    h2 {
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }

  .feature__block {
    position: relative;
    height: 100%;
    @media (max-width: 480px) {
      padding: 20px 0;
    }
  }
`

export default WhyUsSectionWrapper
