import PropTypes from "prop-types"
import React from "react"
import striptags from "striptags"

import Container from "../../components/UI/Container"
import Heading from "../../components/Heading"
import Text from "../../components/Text"
import ChartWrapper, {
  ChartContent,
  ChartDetails,
  ChartImage,
  Items,
} from "./chart.style"

const Chart = ({ title, content }) => {
  const { items, primary } = content
  return (
    <ChartWrapper>
      <Container width="1154px">
        <Heading
          as={primary.title.html.substring(1, 3)}
          content={striptags(primary.title.html)}
          {...title}
        />
        <ChartContent>
          <ChartImage>
            <img
              loading="lazy"
              src={primary.image.url}
              alt="statistics graph"
            />
          </ChartImage>
          <ChartDetails>
            {items.map((item, index) => {
              return (
                <Items key={index} color={item.color}>
                  <div>
                    <span />
                    <Text
                      content={item.occupation.text}
                      className="occupation"
                    />
                  </div>
                  <Text content={item.percent_number} className="number" />
                </Items>
              )
            })}
          </ChartDetails>
        </ChartContent>
      </Container>
    </ChartWrapper>
  )
}

Chart.propTypes = {
  title: PropTypes.object,
  content: PropTypes.object,
}

Chart.defaultProps = {
  title: {
    fontSize: ["26px", "32px", "40px", "40px"],
    fontWeight: "700",
    color: "#000",
    lineHeight: "40px",
    mb: ["40px", "40px", "60px", "90px", "90px"],
    textAlign: ["center", "center", "center", "left", "left"],
  },
}

export default Chart
