import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.div`
  position: relative;
  background-color: ${themeGet("colors.lightGrey")};
  padding: 140px 0px 0px 0px;
  height: 1000px;
  @media only screen and (max-width: 1220px) {
    padding: 100px 0px 0px 0px;
    height: 1000px;
  }
  @media only screen and (max-width: 1100px) {
    height: auto;
  }
  @media only screen and (max-width: 990px) {
    padding: 80px 0px 0px 0px;
  }
  @media only screen and (max-width: 480px) {
    padding: 50px 0px 0px 0px;
  }
  .content {
    display: flex;
    @media only screen and (max-width: 1100px) {
      flex-direction: column-reverse;
    }
  }
`

export const ImageWrapper = styled.div`
  .gatsby-image-wrapper {
    position: absolute !important;
    @media only screen and (max-width: 1100px) {
      position: relative !important;
      margin-bottom: 40px;
    }
    @media only screen and (max-width: 480px) {
      position: relative !important;
      margin-bottom: 30px;
    }
  }
  .image {
    width: 1028px;
    height: 645px;
    margin-top: -165px;
    @media only screen and (max-width: 1100px) {
      margin-top: 0px;
      width: auto;
      height: 545px;
    }
    @media only screen and (max-width: 990px) {
      width: auto;
      height: 445px;
    }
    @media only screen and (max-width: 768px) {
      width: auto;
      height: 400px;
    }
    @media only screen and (max-width: 480px) {
      width: auto;
      height: 245px;
    }
  }
`

export const TextWrapper = styled.div`
  width: 37%;
  margin-right: 120px;
  @media only screen and (max-width: 1100px) {
    width: 100%;
  }

  .details {
    margin-bottom: 60px;
    @media only screen and (max-width: 990px) {
      margin-bottom: 30px;
    }
  }

  .intro {
    font-size: 22px;
    font-weight: 300;
    line-height: 40px;
    margin: 0px 0px 40px 0px;
    @media only screen and (max-width: 990px) {
      font-size: 24px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
    margin: 0px;
    @media only screen and (max-width: 990px) {
      font-size: 22px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  .content {
    font-size: 18px;
    line-height: 30px;
    font-weight: 300;

    @media only screen and (max-width: 990px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 16px;
      line-height: 28px;
    }
  }

  .seperator {
    border-top: 2px solid ${themeGet("colors.mainBlue")};
    margin: 4px 0 8px 0;
    width: 40px;
    margin-left: 0;
  }
`

export default SectionWrapper
