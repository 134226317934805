import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const ChartWrapper = styled.div`
  position: relative;
  background: linear-gradient(180deg, #ffffff 70%, #f9f9f9 30%);
  padding: 0px 30px 0px 30px;

  @media (max-width: 1220px) {
    padding: 0px 30px 0px 30px;
  }
  @media (max-width: 480px) {
    padding: 0px 30px 0px 30px;
  }

  .container {
    background-color: ${themeGet("colors.lightBlue")};
    padding: 80px 80px;
    @media (max-width: 990px) {
      padding: 80px 80px;
      flex-wrap: wrap;
      justify-content: center;
    }
    @media only screen and (max-width: 480px) {
      padding: 30px 30px 30px;
    }
  }
`

export const ChartContent = styled.div`
  display: flex;
  @media only screen and (max-width: 990px) {
    flex-direction: column;
  }
`

export const ChartImage = styled.div`
  width: 65%;
  .image {
    max-width: 400px;
    @media only screen and (max-width: 990px) {
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 990px) {
    width: 100%;
    margin-bottom: 32px;
  }
  @media only screen and (max-width: 480px) {
    width: 90%;
    margin: 0 auto 32px auto;
  }
`
export const ChartDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  @media only screen and (max-width: 990px) {
    width: 100%;
  }

  .occupation {
    font-weight: 300;
    color: ${themeGet("colors.black")};
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 0px;
    @media only screen and (max-width: 990px) {
      font-size: 22px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 18px;
    }
  }

  .number {
    color: ${themeGet("colors.black")};
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 0px;
    @media only screen and (max-width: 990px) {
      font-size: 22px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 18px;
    }
  }
`

export const Items = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  @media only screen and (max-width: 480px) {
    margin: 10px 0;
  }

  div {
    display: flex;
    align-items: center;
    @media only screen and (max-width: 990px) {
      margin-bottom: 8px;
    }
    span {
      width: 20px;
      height: 20px;
      background-color: ${props => props.color};
      margin-right: 32px;
      @media only screen and (max-width: 990px) {
        margin-right: 24px;
      }
      @media only screen and (max-width: 480px) {
        margin-right: 16px;
      }
    }
  }
`

export default ChartWrapper
