import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Banner from "../containers/Banner"
import Breadcrumbs from "../containers/Breadcrumbs"
import Chart from "../containers/Chart"
import Consultation from "../containers/Consultation"
import Layout from "../containers/Layout"
import Mission from "../containers/Mission"
import SEO from "../components/SEO"
import Statistics from "../containers/Statistics"
import WhoWeAre from "../containers/WhoWeAre"
import WhyUs from "../containers/WhyUs"
import { ContentWrapper } from "../containers/alpacked.style"

const AboutPage = ({ location }) => {
  const aboutData = useStaticQuery(graphql`
    query AboutUs {
      prismicAbout {
        data {
          seo_title {
            text
          }
          seo_short_description {
            text
          }
          seo_description {
            text
          }
          image_link_preview {
            fluid {
              src
            }
          }
          body {
            ... on PrismicAboutBodyBanner {
              id
              items {
                title {
                  html
                }
                subtitle
                image {
                  url
                }
              }
            }
            ... on PrismicAboutBodyStatistics {
              id
              items {
                title {
                  html
                }
                subtitle
              }
            }
            ... on PrismicAboutBodyChart {
              id
              items {
                color
                occupation {
                  text
                }
                percent_number
              }
              primary {
                title {
                  html
                }
                image {
                  url
                }
              }
            }
            ... on PrismicAboutBodyWhoWeAre {
              id
              whoWeAre: primary {
                column_a {
                  html
                }
                column_b {
                  html
                }
                title {
                  html
                }
                title_colored {
                  html
                }
                subtitle {
                  html
                }
              }
            }
            ... on PrismicAboutBodyMission {
              id
              primary {
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1400, quality: 72) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
                title {
                  html
                }
                subtitle {
                  html
                }
                title_colored {
                  html
                }
              }
              items {
                title {
                  html
                }
                content
              }
            }
            ... on PrismicAboutBodyWhyUs {
              id
              WhyUs: primary {
                title {
                  html
                }
                title_colored {
                  html
                }
                subtitle {
                  html
                }
              }
              items {
                title {
                  text
                }
                content
              }
            }
          }
        }
      }
    }
  `)
  const {
    body,
    seo_description,
    seo_title,
    seo_short_description,
    image_link_preview,
  } = aboutData.prismicAbout?.data
  return (
    <>
      <SEO
        image={image_link_preview.fluid?.src}
        location={location}
        title={seo_title.text}
        shortDesc={seo_short_description.text}
        description={seo_description.text}
      />
      <Layout pathname="/about">
        <ContentWrapper>
          <Banner
            title={body[0].items[0].title.html}
            subtitle={body[0].items[0].subtitle}
            image={body[0].items[0].image.url}
          />
          <Statistics content={body[1].items} />
          <Breadcrumbs page="about" />
          <WhoWeAre content={body[2].whoWeAre} />
          <Chart content={body[3]} />
          <Mission content={body[4]} />
          <WhyUs content={body[5]} />
          <Consultation pathname="/about" />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default AboutPage
